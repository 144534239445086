interface MailProps {
  to: string;
  locals: object;
  template: string;
  attachments: object;
}

export class Mail {
  private props: MailProps;

  constructor(props: MailProps) {
    this.props = props;
  }

  // GETTERs and SETTERs
  get to(): string {
    return this.props.to;
  }

  get locals(): object {
    return this.props.locals;
  }

  get template(): string {
    return this.props.template;
  }

  get attachments(): object {
    return this.props.attachments;
  }
}
