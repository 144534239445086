
import { Component, Vue } from 'vue-property-decorator';
import { JnService } from '@/services/jn.service';
import JnProgress from '@/views/common/Progress.vue';
import { Mail } from '@/models/mail.model';
import { ValidationObserver } from 'vee-validate';
import { EmailTemplates } from '@/models/enums.model';

@Component({
  components: {
    JnProgress,
    ValidationObserver,
  },
})
export default class JnContattiForm extends Vue {
  fullName = '';
  email = '';
  subject = '';
  message = '';

  loading = false;
  alert = false;
  alertMessage = '';
  alertTimeout = 6000;
  messageSent = false;

  async submit() {
    this.loading = true;
    try {
      await JnService.sendMail(
        new Mail({
          to: process.env.VUE_APP_JURANEWS_MAIL!,
          template: EmailTemplates.CONTATTO,
          locals: {
            fullName: this.fullName,
            email: this.email,
            message: this.message,
          },
          attachments: [],
        })
      );
      this.messageSent = true;
    } catch (err) {
      this.alert = true;
      this.alertMessage = err;
    }
    this.loading = false;
  }
}
