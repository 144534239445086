
import { Component, Vue, Prop } from 'vue-property-decorator';
import JnContattiForm from '@/views/contatti/ContattiForm.vue';
import MetaInfo from 'vue-meta';

@Component({
  components: { JnContattiForm },
  metaInfo(this: JnContatti): MetaInfo {
    return { title: 'JuraNews - Contatti' };
  },
})
export default class JnContatti extends Vue {}
